import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getFreetrail, getPackages, GetInitiative, ShareScore, ShareUserDetail, DeleteScorecard, ReadAccessChange, Logout, get_assigned_left } from "../service/auth";
import InovationBoardListComponents from "./InovationBoardListComponents";
import ListComponent from "./ListComponent";
import { Alert, Button, Modal, Form } from "react-bootstrap";
import SharedScore from "./SharedScore";
import { useDispatch, useSelector } from "react-redux";
import { setScoreValue } from "../Reducer/scorereducer";
import Multiselect from "multiselect-react-dropdown";
import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { AllOutTwoTone } from "@mui/icons-material";

export default function Dashboard() {
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");

  const [processing, setProcessing] = useState(true)
  const [estimation, setEstimation] = useState(false);
  const [gateZero, setGateZero] = useState(false);

  const [showAlertMsg, setShowAlertMsg] = useState({ type: "", msg: '' })
  const [showAlert, setShowAlert] = useState(false);
  const [max, setMax] = useState(0);
  const [createdAt, setCreatedAt] = useState("");
  const [estimationStatus, setEstimationStatus] = useState(true);
  const [show, setShow] = useState(false)

  const modalClose = () => {
    setShow(false);
    setShareEmail('')
    setWritePermission('0')
    setScoreName('')
    setMultiselectValue([])
    setScoreAlert(false)
    setShowAlertMsg({ type: '', msg: '' })
  }

  const modalShow = () => {
    handleData();
    setShow(true);
  };

  const [scorecardName, setscorecardName] = useState([])

  const getShareUserDetails = () => {
    try {
      ShareUserDetail().then((res) => {
        if (res.data.success) {
          // console.log(res.data)
          setShareUsers(res.data.data)
          $("#scoretable").DataTable().destroy()
          handleData()
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  const handleData = () => {
    $(document).ready(function () {
      $("#scoretable").DataTable({
        order: [[1, "desc"]],
        bDestroy: true,
        "language": {
          "emptyTable": "No scorecards shared yet"
        }
      });
    });
  }

  useEffect(() => {
    try {
      GetInitiative().then((res) => {
        setscorecardName(res.data.data)
      });
    } catch (err) {
      console.log(err);
    }

    let userData = localStorage.getItem("User");
    let user = JSON.parse(userData);
    setFullName(user.user.name);
    setEmail(user.user.email);
    setToken(user.access_token);
    handleData()
    checkEstimation();
    checkGateZero();
    getShareUserDetails()
  }, [])

  let user_email = localStorage.getItem("email");

  const checkEstimation = async () => {
    let result = await getPackages(user_email);
    let assigned_packages = await get_assigned_left();

    if (result.data.package_data.length) {
      let estimation = result.data.package_data.filter((item) => {
        if (item.type == "estimation") {
          return item
        }
      })

      let estimation_free = result.data.package_data.filter((item) => {
        if (item.type == "estimation_free") {

          return item
        }
      })

      if (estimation.length) {
        setCreatedAt(estimation[0].createdAt)
        setEstimation(true);

        let Remaining_days = estimation[0].days_left

        if (Remaining_days > 0) {
          setEstimation(true);
        } else {
          setEstimation(false);
        }

      } else if (estimation_free.length) {
        setCreatedAt(estimation_free[0].createdAt)
        setEstimation(true);
        let Remaining_days = estimation_free[0].days_left
        if (Remaining_days > 0) {
          setEstimation(true);
        } else {
          setEstimation(false);
        }
      }
    }
    if (assigned_packages) {
      assigned_packages?.data?.data.map((e) => {
        if (e.type === "estimation") {
          setEstimation(true);
        }
      })
    }
  };

  const checkGateZero = async () => {
    let result = await getPackages(user_email);
    // console.log(result);
    let assigned_packages = await get_assigned_left();
    if (result.data.package_data) {
      let gatezero = result.data.package_data.filter((item) => {
        if (item.type == "gatezero") {
          return item
        }
      })
      let gatezero_free = result.data.package_data.filter((item) => {
        if (item.type == "gatezero_free") {
          return item
        }
      })
      if (gatezero.length) {
        let allowed = gatezero[0]?.scorecard_limit_left;
        // console.log(allowed)
        if (allowed == -1) {
          setGateZero(true)
        }
        else if (allowed > 0) {
          setGateZero(true)
        }
        else if (allowed == 0) {
          let assigned_scorecards = 0
          assigned_packages.data.data.map((e) => {
            // console.log(e.scorecard_limit_left)
            if (e?.scorecard_limit_left !== null) {
              assigned_scorecards += parseInt(e?.scorecard_limit_left);
            }
          });
          if (assigned_scorecards == '00' || assigned_scorecards != null) {
            setGateZero(false)
          } else {
            setGateZero(true)
          }
        }
      }

      else if (gatezero_free) {
        let allowed = gatezero_free[0]?.scorecard_limit_left

        if (allowed == -1) {
          setGateZero(true)
        }

        else if (allowed > 0) {
          setGateZero(true)
        }
        else if (allowed == 0) {
          let assigned_scorecards = 0
          assigned_packages.data.data.map((e) => {
            if (e?.scorecard_limit_left !== null) {
              assigned_scorecards += parseInt(e?.scorecard_limit_left);
            }
          });
          if (assigned_scorecards == '00' || assigned_scorecards == null) {
            setGateZero(false)
          } else {
            setGateZero(true)
          }
        }
      }
    } else {

    }
  };

  function AlertShow(props) {
    if (showAlert) {
      return (
        <div >
          <Alert className="w-100" variant={props.type} onClose={() => { setShowAlert(false); setShowAlertMsg({ type: '', msg: '' }) }} dismissible>
            <span style={{ marginRight: 30 }}>Alert !</span>
            <span>{props.text}</span>
          </Alert>
        </div>
      );
    }
  }

  useEffect(() => {
    checkEstimation()
    checkGateZero()
  }, [max])

  const [shareEmail, setShareEmail] = useState('');
  const [writePermission, setWritePermission] = useState('0');
  const [scorename, setScoreName] = useState('')
  const [scoreAlert, setScoreAlert] = useState(false)
  const [shareUsers, setShareUsers] = useState([])

  const [multiSelectValues, setMultiselectValue] = useState([])

  const dispatch = useDispatch()

  const parseEmail = (emails) => {
    let _emails = ''
    let data = emails.split(",");
    data.forEach(e => {
      _emails += e.trim() + ',';
    });
    let trimedEmails = _emails.slice(0, _emails.length - 1);
    setShareEmail(trimedEmails)
  }

  const parseScorename = (scoreid) => {

    setMultiselectValue(scoreid)
    let data = ""
    scoreid.map((item) => {
      data += item.id + ","
    })
    let trimScoreid = data.slice(0, data.length - 1)
    setScoreName(trimScoreid)
  }

  const [options, setOptions] = useState([])

  const shareScorecard = async () => {
    setScoreAlert(false)
    setShowAlertMsg({ type: '', msg: '' })

    if (!scorename) {
      setScoreAlert(true)
      setShowAlertMsg({ type: 'danger', msg: 'please select a scorecard' })
      return
    }

    if (shareEmail == '') {
      setScoreAlert(true)
      setShowAlertMsg({ type: 'danger', msg: 'please enter an email' })
      return
    }
    setProcessing(true);
    ShareScore(scorename, writePermission, shareEmail).then((result) => {
      // console.log(result)
      if (result.data.success) {

        setShareEmail('')
        setWritePermission('0')
        setScoreName('')
        setMultiselectValue([])
        getShareUserDetails();

        setTimeout(() => {
          setScoreAlert(true)
          setShowAlertMsg({ type: 'success', msg: 'Scorecard shared successfully !' })
        }, 100)

      } else {
        setScoreAlert(true)
        setShowAlertMsg({ type: 'danger', msg: result.data.error })
      }
    }).catch((err) => {
      if (err.response.status == 403) {
        Logout()
        window.location.href = '/sign'
      } else {
        setScoreAlert(true)
        setShowAlertMsg({ type: 'danger', msg: 'Some error occured please try after sometime !' })
      }
    }).finally(() => {
      setProcessing(false);
    })
  }

  const multiSelect = () => {

    let data = []
    scorecardName.map((item) => {
      data.push({ name: item.scorecard_name, id: item.evaluation_id })

    })
    return data
  }

  useEffect(() => {
    setScoreAlert(false)
    setShowAlertMsg({ type: '', msg: '' })
  }, [scorename, writePermission, shareEmail])

  const deleteSharedScorecard = (score_id, user_id) => {
    DeleteScorecard(score_id).then((res) => {
      // console.log(res)
      if (res.data.success) {
        setScoreAlert(true)
        setShowAlertMsg({ type: 'success', msg: res.data.message })
        getShareUserDetails();
      } else {
        setScoreAlert(true)
        setShowAlertMsg({ type: 'danger', msg: res.data.message })
      }
    }).catch((err) => {
      setScoreAlert(true)
      setShowAlertMsg({ type: 'danger', msg: 'scorecard not deleted !' })
    })
  }

  const [editAccessData, setEditAccessData] = useState({})
  const [edit, setEdit] = useState(false)

  const modalEdit = () => {
    ReadAccessChange(editAccessData.scoreId, writePermission).then((result) => {
      if (result.data.success) {
        getShareUserDetails();
        setEdit(false)
        setShow(true)
        setWritePermission('0')
        setTimeout(() => {

          setScoreAlert(true)
          setShowAlertMsg({ type: 'success', msg: 'Updated successfully !' })
        }, 100)
      } else {
        setScoreAlert(true)
        setShowAlertMsg({ type: 'danger', msg: 'Some error occured !' })
      }

    }).catch((err) => {
      if (err.response.status == 403) {
        Logout()
        window.location.href = '/sign'
      } else {
        setEdit(false)
        setShow(true)
        setScoreAlert(true)
        setShowAlertMsg({ type: 'danger', msg: 'Some error occured !' })
      }
    })
  }

  const editAccess = () => {
    return (
      <Modal show={edit} onHide={() => setEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black", marginLeft: "20px", fontWeight: 700 }}>Change Scorecard Access</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "20px" }}>
          <Form style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Form.Group
              className="form-group"
              controlId="formGridState"
              style={{ marginBottom: 10, width: "50%" }}
            >
              <Form.Label style={{ color: 'black', fontSize: '16px' }}>User</Form.Label>
              <Form.Control style={{ width: '250px', height: '35px', fontSize: '14px', marginBottom: '10px' }} readOnly value={editAccessData.username}></Form.Control>
              <Form.Label style={{ color: 'black', fontSize: '16px' }}>Scorecard Name</Form.Label>
              <Form.Control style={{ width: '250px', height: '35px', fontSize: '14px' }} readOnly value={editAccessData.scoreName}></Form.Control>

            </Form.Group>
            <Form.Group style={{ display: "flex", alignItems: 'center', marginTop: "10px" }}>
              <Form.Check
                inline
                name="group"
                type="checkbox"
                defaultChecked={editAccessData.allow_write == '1' ? true : false}
                onChange={(e) => e.target.checked ? setWritePermission("1") : setWritePermission("0")}
              />
              <Form.Label style={{ color: "black", marginTop: 6 }}>Readonly</Form.Label>
            </Form.Group>
          </Form>
          <hr />
          <div style={{ display: "flex", justifyContent: 'flex-end' }}>
            <Button className="btn btn-primary button-blue border-0 text-dark" style={{ marginTop: "10px", marginBottom: "10px", padding: "6px 22px", fontSize: '12px' }} onClick={modalEdit}>Submit</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <div>
      <div className="dashbord-page">
        <div className="container">
          <AlertShow type={showAlertMsg.type} text={showAlertMsg.msg} />
          {editAccess()}
          <Modal className="sharePopup" show={show} onHide={modalClose} >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black", marginLeft: "20px", fontWeight: 700 }}>Share Scorecard</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "20px" }}>
              {scoreAlert && <Alert className="w-100" variant={showAlertMsg.type} onClose={() => { setScoreAlert(false); setShowAlertMsg({ type: '', msg: '' }) }} dismissible>
                <span style={{ marginRight: 30 }}>Alert !</span>
                <span>{showAlertMsg.msg}</span>
              </Alert>}
              {/* <div > */}
              <Form style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Form.Group
                  className="form-group"
                  controlId="formGridState"
                  style={{ marginBottom: 15, width: "50%" }}
                >
                  <Form.Label style={{ color: 'black' }}>Select Scorecard</Form.Label>
                  {/* <Form.Select
                      onChange={(e) => {
                        setScoreName(parseInt(e.target.value));
                      }}
                    >
                      <option value="">-- Select One --</option>
                      {scorecardName.map((item) => (
                        <option value={item.evaluation_id}>{item.scorecard_name}</option>
                      ))}

                    </Form.Select> */}
                  <Multiselect
                    options={multiSelect()}
                    style={{
                      searchBox: {
                        innerWidth: '100%'
                      },
                      option: {
                        color: 'black'
                      }
                    }}
                    selectedValues={multiSelectValues}
                    onSelect={(e) => parseScorename(e)}
                    onRemove={(e) => parseScorename(e)}
                    avoidHighlightFirstOption={true}
                    showCheckbox={true}
                    hideSelectedList={true}

                    displayValue="name"
                    placeholder={multiSelectValues.length + ' / ' + multiSelect().length + ' scorecards selected'}
                  />
                </Form.Group>
                <Form.Group style={{ display: "flex", alignItems: 'center', marginTop: "10px" }}>
                  <Form.Check
                    inline
                    name="group"
                    type="checkbox"
                    defaultChecked={false}
                    checked={writePermission == '0' ? false : true}
                    onChange={(e) => e.target.checked ? setWritePermission("1") : setWritePermission("0")}
                  />
                  <Form.Label style={{ color: "black", marginTop: 6 }}>Readonly</Form.Label>
                </Form.Group>
              </Form>
              <textarea value={shareEmail} style={{ width: "100%", height: "100px", padding: 5, marginBottom: 5 }} onChange={(e) => { parseEmail(e.target.value) }} placeholder="Enter emails to share"></textarea>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button className="btn btn-primary button-blue text-dark border-0" onClick={shareScorecard} style={{ marginTop: "10px", marginBottom: "10px", padding: "6px 22px", fontSize: '12px' }}>Share</Button>
              </div>
              <div style={{ marginTop: '15px', marginBottom: '10px' }}>
                <h3 style={{ color: 'black' }}>Shared Scorecards</h3>
              </div>
              <br />
              <div className="table-responsive"  >
                <table
                  id="scoretable"
                  className="table-list table table-striped table-bordered"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Scorecard Name</th>
                      <th>Date</th>
                      <th>Readonly</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shareUsers && shareUsers.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td><div><div>{item.name}</div> <div className="text-muted fs-6">{item.email}</div></div></td>
                          <td>{item.share_scorecard.scorecard_name}</td>
                          <td>{item.share_scorecard.date_time}</td>
                          <td>{item.share_scorecard.allow_write == '1' ? "True" : 'False'}</td>
                          {/* <td><Button onClick={() => deleteSharedScorecard(item.share_scorecard.id, item.u_id)}>delete</Button></td> */}
                          <td>
                            <ul className="table-icon">
                              <li>
                                <button className="edit" onClick={() => { modalClose(); setEdit(true); setEditAccessData({ scoreName: item.share_scorecard.scorecard_name, allow_write: item.share_scorecard.allow_write, scoreId: item.share_scorecard.id, username: item.name }) }} title="edit">
                                  <i className="fa-solid fa-pencil"></i>
                                </button>
                              </li>
                              <li>
                                <button className="delete" onClick={() => deleteSharedScorecard(item.share_scorecard.id, item.u_id)} title="delete">
                                  <i className="fa-solid fa-trash-can"></i>
                                </button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
          </Modal>

          <div className="dashboard-title">
            <div className="dashboard-patel">
              <h1>Dashboard</h1>
              <h3>
                {fullname} ({email})
              </h3>
            </div>
            <div className="dashboard-button2">
              <Link
                onClick={modalShow}
                className="dashboard-button button"
                variant="primary"
              >
                <i className="fa-solid fa-square-plus"></i>Share
              </Link>
              {gateZero ? <Link
                onClick={() => {
                  localStorage.setItem('write_permission', '0')
                }}
                to="/user/initiative"
                className="dashboard-button button"
                variant="primary"
              >
                <i className="fa-solid fa-square-plus"></i>New Investment Evaluation
              </Link>
                :
                <Link
                  onClick={() => {
                    setShowAlert(true);
                    setShowAlertMsg({ type: 'danger', msg: 'Scorecard limit reached please upgrade your plan!' })
                  }}
                  to="/user"
                  className="dashboard-button button"
                  variant="primary"
                >
                  <i className="fa-solid fa-square-plus"></i>New Investment Evaluation
                </Link>
              }
              <Link
                to="/user/innovationboard"
                className="dashboard-button button"
                variant="primary"
              >
                <i className="fa-solid fa-square-plus"></i>Submit New Idea
              </Link>
              {estimation && (
                <a
                  href={"https://estimates.gatezerodigital.com/?param=" + token}
                  className="dashboard-button button"
                  variant="primary"
                  target="_blank"
                >
                  <i className="fa-solid fa-square-plus"></i>New Project Estimate
                </a>
              )}
            </div>
          </div>
          <div>
            <ListComponent maxScorecard={setMax} />
          </div>
          <div style={{ marginTop: "50px" }}>
            <div className="text-dark fs-1" style={{ fontFamily: "AcuminProCond-Semibold" }} >Innovation Board</div>
            <div>
              <InovationBoardListComponents />
            </div>
          </div>
          <div style={{ marginTop: "50px" }}>
            <div className="text-dark fs-1" style={{ marginBottom: "20px", fontFamily: "AcuminProCond-Semibold" }}>Shared Scorecard</div>
            <div>
              <SharedScore maxScorecard={setMax} />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
