import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getFreetrail, getPackages, products } from "../service/auth";

export default function Pricing() {

  const [products_, setproducts] = useState([])
  const [purchasedPlans, setPurchasedPlans] = useState([])

  const packages_data = async () => {

    let packages = await products();
    // console.log(packages.data.data)
    if (packages.data.success) {
      setproducts(packages.data.data)
    } else {

    }
  }


  useEffect(() => {
    packages_data()
  }, [])

  return (
    <div className="product-admin-page">
      <div className="container">
        {(products_?.length > 0) && products_.map((e) => {
          if (e.ProductPackages.length == 0) {
            return
          } if (e.ProductName == "VideoTraining" || e.ProductName == "PersonalTraining") {
            return
          }
          return (
            <>
              <h1>{e.ProductName}</h1>
              <div className="row product-section">
                {e.ProductPackages.map((ele) => {
                  console.log(ele);
                  return (
                    <div className="col-md-3" style={{ marginBottom: "10px" }}>
                      <Card>
                        <Card.Body>
                          <Card.Text>
                            <ul>
                              <li className="un-hd">{ele.PackageName}</li>
                              {ele.PackageType == 'gatezero' && <li>{ele.NoOfUsers == '-1' ? "Unlimited" : ele.NoOfUsers} Users - {ele.NoOfScorecard == '-1' ? "Unlimited" : ele.NoOfScorecard} scorecards</li>}
                              {ele.PackageType == "estimation" && <li>{ele.NoOfUsers == '-1' ? "Unlimited" : ele.NoOfUsers} {ele.NoOfUsers == '-1' ? "Users" : ele.NoOfUsers > 1 ? "Users" : "User"}</li>}
                              <li>{ele.PackageDescription}</li>
                            </ul>
                            <div className="price-month">
                              <span>{`${ele.CostInUSD == '0' ? 'Free' : "$" + ele.CostInUSD}`}</span>{ele.CostInUSD == '0' ? "/" + ele?.FreeTrialPeriodInDays + ' days' || '0' : "/month"} {`${ele.IsOneTime == "0" ? "" : "- One Time Payment"}`}
                            </div>
                          </Card.Text>
                          <Link
                            to={`/Signupcheckout/${e.ProductName.toLowerCase()}:${ele.PackageID}`}
                            state={{ from: ele.CostInUSD, type: e.ProductName.toLowerCase(), productData: e, packageData: ele }}
                            className="btn btn-default"
                            id={`${ele.CostInUSD}`}
                          >
                            Activate
                          </Link>
                        </Card.Body>
                      </Card>
                    </div>
                  )
                })}
              </div >
            </>
          )
        })}
      </div>
    </div>
  );
}

{/* <div className="col-md-3" style={{ marginBottom: "10px" }}>
<Card>
  <Card.Body>
    <Card.Text>
      <ul>
        <li className="un-hd">1 free Scorecard</li>
        <li>7-Days Free Trial</li>
      </ul>
      <div className="price-month">
        <span>Free</span>
      </div>
    </Card.Text>
      <Link
        to="/Signupcheckout/gatezero:free"
        state={{ from: "free" , type: "gatezero_free" }}
        className="btn btn-default"
      >
        Activate
      </Link>
  </Card.Body>
</Card>
</div> */}
