import React from "react";
import { Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Footer = () => {

    const navigate = useNavigate();

    return (
        <>
            {/* <div className="footer">
                <Container>
                    <Row>
                        <div className="footer-content">
                            <div className="footer-list">
                                <div className="footer-logo">
                                    <a href="/"><img src="img/logo.png" alt="GATEZERO" /></a>
                                </div>
                                <p>Copyright © 2022 GATEZERO <br />All Rights Reserved.</p>
                            </div>
                            <div className="footer-list">
                                <h4>quick Links</h4>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/about-us">About Us</a></li>
                                    <li><a href="/contact-us">Contact Us</a></li>
                                </ul>
                            </div>
                            <div className="footer-list">
                                <h4>get in touch</h4>
                                <ul className="social">
                                    <li><a href=""><img src="img/in-icon.png" alt="GATEZERO" /></a></li>
                                    <li><a href=""><img src="img/twi-icon.png" alt="GATEZERO" /></a></li>
                                    <li><a href=""><img src="img/int-icon.png" alt="GATEZERO" /></a></li>
                                </ul>
                            </div>

                        </div>

                    </Row>
                </Container>
            </div> */}

            <section class="copyright-section">
                <div class="container">
                    <div class="copyright-wrapper">
                        <div class="our-link">
                            <a href="#" onClick={() => navigate('/')}>Home </a><span>|</span>
                            <a href="#" onClick={() => navigate('about-us')}>About us</a><span>|</span>
                            <a href="#" onClick={() => navigate('contact-us')}>Contact us</a>
                        </div>
                        <div class="copyright-social">
                            <ul>
                                <li><a href="#"  ><img src="/img/images/f-b.png" /></a></li>
                                <li><a href="#"  ><img src="/img/images/twitter.png" /></a></li>
                                <li><a href="#"  ><img src="/img/images/insta.png" /></a></li>
                            </ul>
                        </div>
                        <div class="All-Rights">
                            <p>Copyright © 2022-2024 GATEZERO All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Footer;