import { Modal } from "react-bootstrap";

const Terms = ({ modalClose, show }) => {
  return (
    <Modal className="signup-terms" show={show} onHide={modalClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: "black", marginLeft: "20px", fontWeight: 700, fontFamily: "AcuminProCond-Semibold" }}>TERMS OF USE</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 10,
            color: "black",
            fontFamily: "Acumin-Pro"
          }}
        >
          <div>This website ( this “Site”) is owned by Zygote Inc., doing business as gatezerodigital.com (“We”, “Us” or “GateZero”). We are providing you with access to our posts, online digital tools, consulting services, training services and this Site (together, our “Services”) subject to the following terms and conditions. By browsing, accessing, using, or registering on this Site or otherwise using our Services, you are agreeing to all of the following terms and conditions, including any policies referred to herein (collectively, these “Terms”). Please read these Terms carefully. We reserve the right to change this Site and these Terms at any time. If you are unwilling to be bound by these Terms, you should not browse, access, use, register for or utilize our Services from the Site. You represent and warrant that you are at least 18 years old.
            These terms contain a binding arbitration clause and class action waiver that impact your rights about how to resolve disputes. If you live in the United States, please read it carefully.
            <div className="modal-sub-heading">Privacy Policy</div>
            Our Privacy Policy, which also governs your visit to our Site and use of our Services, can be found on this page. Please review our Privacy Policy for information on how We collect, use, and share information about our users.
            <div className="modal-sub-heading">Use of the Services</div>
            Subject to your compliance with these Terms, We grant you a limited, non-exclusive, non-transferable, non-sublicensable license to access and make use of the Services in your personal or professional capacity, and, if applicable, for the benefit of your employer. Subject to the foregoing, this license grant does not include: (a) any resale or conveyance of the substance of the Services to a third party; (b) making derivative uses of the Services; or (d) use of any data mining, robots, or similar data gathering and extraction methods on this Site. Any breach of these Terms shall result in the immediate revocation of the license granted in this paragraph without notice to you.
            <div className="modal-sub-heading">Intellectual Property Rights</div>
            Unless otherwise indicated, the Services are our proprietary property and all information, materials, concepts, diagrams, scorecards, methods, procedure, source code, databases, functionality, software, website designs, audio, video, text, photographs, graphics, trademark, service marks and logos used in connecting with the Services (collectively, the “Content”) are exclusively owned or controlled by Us or licensed to Us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, foreign jurisdictions, and international conventions. We retain ownership, copyright and all intangible property rights with respect to the Content. We also retain ownership and copyright of all intangibles and data developed within the scope of the Services provided by Us. The Content is provided “AS IS” for your information and personal use only. Except as expressly provided in these Terms, no part of the Services or Site and no Content may be copied, reproduced, aggregated, reused, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
            <div className="modal-sub-heading">Account</div>
            In order to access some features of this Site, you may be required to register, and We may assign to you, or you may be required to select, a password and username or account identification. If you register, you agree to provide Us with accurate and complete registration information, and to inform Us immediately of any updates or other changes to such information. You are solely responsible for protecting the security and confidentiality of the password and identification assigned to you. You shall immediately notify Us of any unauthorized use of your password or identification or any other breach or threatened breach of this Site’s security. Each time you use a password or identification, you will be deemed to be authorized to access and use the Site in a manner consistent with these Terms, and We have no obligation to investigate the authorization or source of any such access or use of this Site. YOU WILL BE SOLELY RESPONSIBLE FOR ALL ACCESS TO AND USE OF THIS SITE BY ANYONE USING THE PASSWORD AND IDENTIFICATION ORIGINALLY SELECTED BY, OR ASSIGNED TO, YOU WHETHER OR NOT SUCH ACCESS TO AND USE OF THIS SITE IS ACTUALLY AUTHORIZED BY YOU, INCLUDING WITHOUT LIMITATION, ALL COMMUNICATIONS AND TRANSMISSIONS AND ALL OBLIGATIONS (INCLUDING WITHOUT LIMITATION FINANCIAL OBLIGATIONS) INCURRED THROUGH SUCH ACCESS OR USE.
            <div className="modal-sub-heading">Electronic Communication</div>
            When you use our Services, this Site, or send emails to Us, you are communicating with Us electronically. You consent to receive communications from Us electronically. We will communicate with you by e-mail or by posting notices on this Site or through our other services. You agree that all agreements, notices, disclosures and other communication that We provide to you electronically satisfy any legal requirements that such communications be in writing.
            <div className="modal-sub-heading">Restrictions on Rights to Use</div>
            <p style={{ margin: "10px 0px" }}>You agree you shall not:</p>
            Download, modify, reproduce, adapt, translate, reverse engineer, create derivative works based upon, publicly display, sell, rent, license, or in any way commercially exploit any portion of this Site or the Services, except and to the extent expressly permitted under these Terms;
            Remove any copyright, trademark or other proprietary rights notice contained in or on the Site;
            Use any robot, spider, site search/retrieval application, or other device to retrieve or index any portion of this Site;
            Create user accounts by automated means or under false or fraudulent pretenses;
            Transmit or upload to this Site any item containing or embodying any virus, worm, defect, malware, Trojan horse, software bomb or other feature designed to damage or degrade in any manner the performance of this Site, any other Web site, or any computer or other device or system, or the enjoyment of this Site by any user;
            Submit to this Site any content that is unlawful or facilitates, constitutes, promotes or encourages illegal activity; and
            Use this Site, intentionally or unintentionally, to violate any applicable local, state, federal or international law.
            <div className="modal-sub-heading">Ownership</div>
            As between you and Us, the Services and all Content including all recommendations, suggestions, images, text, graphics, software, source code and other aspects thereof, all improvements or modifications thereof, all derivative works based thereon, and the collection, arrangement, and assembly of this Site (collectively, the “Site Content”), including all copyrights, trademarks, and other intellectual property or proprietary rights in the foregoing, are owned by Us or our licensors and protected by applicable intellectual property laws. The use of any of Our Site Content without our express written consent is strictly prohibited. Nothing in these Terms shall be deemed to grant to you or any other user any license or right in or to any of Our patents, copyrights, trademarks, trade secrets or other proprietary rights.
            <div className="modal-sub-heading">Links</div>
            This Site may contain links to other sites on the Internet that are owned and operated by third parties. You acknowledge that We are not responsible for the operation of or content located on or through any such site.
            <div className="modal-sub-heading">Termination</div>
            You may terminate the Terms at any time by closing your account, discontinuing your use of the Services, and providing Us with a notice of termination. We reserve the right, without notice and in our sole discretion, to terminate your right to use the Services, or any portion of this Site, and to block or prevent your future access to and use of the Services or any portion of this Site.
            <div className="modal-sub-heading">Limitation of Liability</div>
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES AND THIS SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATESOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN U.S. STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR  LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
            <div className="modal-sub-heading">Indemnification</div>
            To the fullest extent permitted by applicable law, you agree to defend, indemnify and hold harmless Us and our subsidiaries and affiliates, and our respective officers, directors, agents, partners, members, employees, independent contractors, service providers and consultants (“Our Related Parties”), from and against any claims, damages, costs, liabilities and expenses (collectively, “Claims”) arising out of or related to (a) your access to and use or misuse of the Services and this Site; (b) your violation of these Terms; and (c) your violation of any rights of another. You agree to promptly notify Us of any third-party Claims, cooperate with Us in defending such Claims and pay all fees, costs and expenses associated with defending such Claims (including but not limited to attorneys’ fees). You further agree that the We shall have the right to control of the defense or settlement of any third-party Claims
            <div className="modal-sub-heading">Disclaimers</div>
            Except as expressly provided, the Site, including all Site Content, and the Services provided on or in connection with this Site are provided on an “AS IS” and “WITH ALL FAULTS” basis without representations, warranties or conditions of any kind, either express or implied. WE DISCLAIM ALL OTHER REPRESENTATIONS, WARRANTIES, CONDITIONS AND DUTIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES, DUTIES OR CONDITIONS: (A) OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR USE, RESULTS, TITLE, AND NON-INFRINGEMENT; AND (B) CREATED BY TRADE USAGE, COURSE OF DEALING OR COURSE OF PERFORMANCE. We do not represent or warrant that the Services and the Site is accurate, complete, reliable, current or error-free. We do not represent or warrant that this Site, our software or our servers are free of viruses or other harmful components.
            <div className="modal-sub-heading">Governing Law</div>
            These Terms and your use of the Site are governed by and construed in accordance with Texas laws applicable to agreements made and to be entirely performed within the state of Texas, without regard to its conflict of law provisions.
            <div className="modal-sub-heading">Dispute Resolution</div>
            <div className="modal-dispute-resolution-heading">Informal Negotiations</div>
            To expedite resolution and control the cost of any dispute, controversy, or claim related to these Terms (each a “Dispute” and collectively, the “Disputes”) brought by either you or Us (individual, a “Party” and collectively, the “Parties”), the Parties agree to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least thirty (30) days before initiating arbitration. Such informal negotiations commence upon written notice from one Party or the other.
            <div className="modal-dispute-resolution-heading">Binding Arbitration</div>
            IF YOU'RE A U.S. RESIDENT, YOU ALSO AGREE TO THE FOLLOWING MANDATORY ARBITRATION PROVISIONS:
            We both agree to arbitrate. You and We agree to resolve any claims relating to these Terms or the Services through final and binding arbitration, except as set forth under Exceptions to Informal Negotiations and Arbitration, below.
            <div className="modal-dispute-resolution-heading">Restrictions</div>
            The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by the law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilize class action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.
            <div className="modal-dispute-resolution-heading">Exceptions to Informal Negotiations and Arbitration</div>
            The parties agree that the following Disputes are not subject to the above provisions concerning informal negotiations and binding arbitration:
            Any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; and
            Any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use.
            <div className="modal-dispute-resolution-heading">Any claim for injunctive relief</div>
            If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitration of any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.
            <div className="modal-sub-heading">Modifications to Site</div>
            We reserve the right to modify or discontinue, temporarily or permanently, this Site or any features or portions thereof without prior notice.
            <div className="modal-sub-heading">Corrections</div>
            There may be information on the Site that contain typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.
            <div className="modal-sub-heading">No Third-Party Beneficiaries</div>
            These Terms are for the benefit of, and will be enforceable by, the parties only. These Terms are not intended to confer any right or benefit on any third party or to create any obligations or liability of a party to any such third party.
            <div className="modal-sub-heading">Miscellaneous</div>
            No agency, partnership, joint venture, or employment relationship is created as a result of these Terms, and you do not have any authority of any kind to bind Us in any respect whatsoever. We may provide you with notices, including those regarding changes to these Terms, by email, regular mail, or postings on this Site. These Terms, which shall be deemed accepted by you upon your use of the Site, constitute the entire agreement among you and Us regarding use of this Site. Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of the enforcement of such right or provision. If any provision of these Terms is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms shall otherwise remain in full force and effect and enforceable. These Terms are not assignable, transferable or sublicensable by you, except with our prior written consent. These Terms include and incorporate by reference Our Privacy Policy, which can be found on this page, and any notices regarding the Site.
            <div className="modal-sub-heading">Questions</div>
            Questions regarding these Terms, Our Privacy Policy, or other policy related material can be directed to  Us at xxx@xxx.com.
          </div>
          <div style={{ margin: "10px 0px" }}>
            Effective Date: January 01, 2023
          </div>
        </div>
      </Modal.Body>
      <Modal.Header>
        <Modal.Title style={{ color: "black", marginLeft: "20px", fontWeight: 700 }}>PRIVACY POLICY</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 10,
            color: "black",
            fontFamily: "Acumin-Pro"
          }}
        >
          <div>This privacy policy (the “Privacy Policy”) governs the manner in which Zygote Inc. doing business as gatezerodigital.com (“We”, “Us” or “GateZero”) collects, uses, maintains and discloses information collected from users (each, a “User”) of gatezerodigital.com (the “Site”). This Privacy Policy applies to the Site and all products and services offered by GateZero (the “Services”) and is subject to our Terms of Use, here <span style={{ color: "red" }}>xxx</span>.
            <div className="modal-sub-heading">Application</div>
            <div className="modal-sub-heading-content-bold">This Privacy Policy relates to User personal data, i.e. data about a User, an individual, from which the User can be identified. This Policy does not apply to any data insofar as it is held, processed, disclosed or published in a form which cannot be linked to a living individual (such as anonymized or aggregated data which cannot directly or indirectly be used to identify a User or to obtain information about a User) ("Anonymized and Aggregated Data"). We may generate or extract Anonymized and Aggregated Data out of any databases containing User personal data and We may make use of any such Anonymized and Aggregated Data for our purposes as We see fit.</div>
            <div className="modal-sub-heading">Personal Identification Information</div>
            We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, place an order, fill out a form, and in connection with other activities, services, features or resources We make available on our Site. Users may be asked for, as appropriate, name, email address, mailing address, phone number, social security number. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to Us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.
            <div className="modal-sub-heading">Non-Personal Identification Information</div>
            We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.
            <div className="modal-sub-heading">Web Browser Cookies</div>
            Our Site may use “cookies” to enhance User experience. User’s web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
            <div className="modal-sub-heading">How We Use Collected Information</div>
            GateZero may collect and use Users personal information for the following purposes:
            <div style={{ margin: '10px 0px' }}>
              <ol>
                <li>To run and operate our Site.</li>
                <li>We may need your information display content on the Site correctly.</li>
                <li>To personalize user experience.</li>
                <li>We may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.</li>
                <li>To improve our Site.</li>
                <li>We may use feedback you provide to improve our Services.</li>
                <li>We may use the information Users provide about themselves when utilizing our Services to provide that service. We do not share this information with outside parties except to the extent necessary to provide our Services.</li>
                <li>We may use the email address to send User information and updates pertaining our Services. It may also be used to respond to their inquiries, questions, and/or other requests.</li>
              </ol>
            </div>
            <div className="modal-sub-heading">How We Protect Your Information</div>
            We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our Site.
            <div className="modal-sub-heading">Sharing Your Personal Information</div>
            We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above. We may use third party service providers to help Us operate our business and the Site or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given Us your permission.
            <div className="modal-sub-heading">Electronic Newsletters</div>
            If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, We include detailed unsubscribe instructions at the bottom of each email or User may contact Us via our Site. We may use third party service providers to help Us operate our business and the Site or administer activities on our behalf, such as sending out newsletters or surveys. We may share your information with these third parties for those limited purposes provided that you have given Us your permission.
            <div className="modal-sub-heading">Third Party Websites</div>
            Users may find advertising or other content on our Site that link to the sites and services of our partners, suppliers, advertisers, sponsors, licensor and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site. In addition, these sites or services, including their content and links, may be constantly changing. These sites and services may have their own privacy policies and customer service policies. Browsing and interaction on any other website, including websites which have a link to our Site, is subject to that website’s own terms and policies.
            <div className="modal-sub-heading">Changes to this Privacy Policy</div>
            GateZero has the discretion to update this Privacy Policy at any time. When We do, We will post a notification on the main page of our Site. We encourage Users to frequently check this page for any changes to stay informed about how We are helping to protect the personal information We collect. You acknowledge and agree that it is your responsibility to review this Privacy Policy periodically and become aware of modifications.
            <div className="modal-sub-heading">Your Acceptance of These Terms</div>
            By using this Site, you signify your acceptance of this Privacy Policy. If you do not agree to this Privacy Policy, please do not use our Site. Your continued use of the Site following the posting of changes to this Privacy Policy will be deemed your acceptance of those changes.
            <div className="modal-sub-heading">Contacting Us</div>
            If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact Us.

            <div className="modal-sub-heading">Questions</div>
            Questions regarding this Privacy Policy, or other policy related material can be directed to  Us at xxx@xxx.com.
          </div>
          <div style={{ margin: "10px 0px" }}>
            Effective Date: January 01, 2023
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default Terms